export default {
    app_name: "LOTTERY",
    //
    admin_login: "Admin Login",
    login: "Login",
    // left navs
    real_time_order_acceptance: "Real-time order acceptance",
    number_distribution: "Number distribution",
    total_order: "Total order",
    order_information: "Order information",
    winning_lottery_number: "Winning lottery number",
    number_limit: "Number limit",
    regional_time_limit: "Regional time limit",
    online_status: "Online status",
    set_account_password: "Set account & password",
    platform_settings: "Platform settings",
    // settings
    platform_access_account: "Platform access account",
    platform_access_password: "Platform access password",
    Change_Password: "Change Password",
    old_password: "old password",
    new_password: "new password",
    new_password_confirmation: "new password confirmation",
    platform_data_remove: "Platform Data Remove",
    time: "Time",
    delete_immediately: "Delete immediately",
    Save_Changes: "Save Changes",
    password_validation_message: "*password must contain at least 8 characters, including UPPER/lowercase and numbers",
    // set account & password
    machine_number: "Machine Number",
    password: "Password",
    register: "Register",
    status: "Status",
    delete: "Delete",
    online: "Online",
    offline: "Offline",
    // online status
    online_machines: "Online Machines",
    offline_machines: "Offline Machines",
    // regional time limit
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    start: "start",
    end: "end",
    disable: "disable",
    modify: "modify",
    // number limit
    number_digits: "Number Digits",
    limit: "Limit",
    save: "Save",
    special_number: "Special Number",
    num: "Num",
    add: "Add",
    number: "Number",
    // winning lottery number
    date: "Date",
    determine: "Determine",
    search: "Search",
    print: "Print",
    // order information
    serial_number: "Ticket serial number",
    winning_order: "Winning order",
    unwinning_order: "Unwinning order",
    paid_order: "Paid order",
    unpaid_order: "Unpaid order",
    pool: "Pool",
    amount: "Amount",
    order_time: "Order time",
    drawing_time: "Drawing time",
    region: "Region",
    winning_amount: "Winning amount",
    winning_payment_time: "Winning payment time",
    total: "Total",
    // total order
    all_machines: "All machines",
    sales: "Sales",
    commission: "Commission",
    redemption_amount: "Redemption amount",
    settlement: "Settlement",
    commission_setting: "Commission Setting",
    confirm: "Confirm",
    general_prizes_rank1_digit4: "General Prizes - Rank 1(4 digits)",
    general_prizes_rank2_digit4: "General Prizes - Rank 2(4 digits)",
    general_prizes_rank3_digit4: "General Prizes - Rank 3(4 digits)",
    general_prizes_rank1_digit3: "General Prizes - Rank 1(3 digits)",
    general_prizes_rank2_digit3: "General Prizes - Rank 2(3 digits)",
    general_prizes_rank3_digit3: "General Prizes - Rank 3(3 digits)",
    general_prizes_rank1_digit2: "General Prizes - Rank 1(2 digits)",
    general_prizes_rank2_digit2: "General Prizes - Rank 2(2 digits)",
    general_prizes_rank3_digit2: "General Prizes - Rank 3(2 digits)",
    grand_prizes_rank1_pool: "Grand Prizes - Rank 1(pool)",
    grand_prizes_rank2_pool: "Grand Prizes - Rank 2(pool)",
    grand_prizes_rank3_pool: "Grand Prizes - Rank 3(pool)",
    consolation_prizes: "Consolation Prizes",
    total_prizes: "Total Prizes",
    // number distribution
    all: "All",
    // real time order acceptance
    total_revenue: "Total Revenue",
    deleted_time: "Deleted time",
    // Validation messages
    Invalid_Number_Format: "Invalid Number Format."
}